import { useEffect, useState } from "react";
import { getAllInventoryAccesory } from "../../../services/InventoryAccessory";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { IoMdSearch } from "react-icons/io";

function SelectAccesory({ onClose, addToCart, warehouseSelected }) {
  const [filterPagination, setFilterPagination] = useState({
    data: {},
    status: false
  });
  const [filterParams, setFilterParams] = useState({
    search: "",
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState('1');
  const [accessories, setAccessories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagesData, setPagesData] = useState({ total: 0, records: 0 });

  const handleQuantityChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (value === '' || parseInt(value) > 0) {
      setQuantity(value);
    }
  };

  const handleAddProduct = (isBulkOrder) => {
    addToCart(isBulkOrder, selectedItem, quantity, true);
    setSelectedItem(null);
    setQuantity('1');
  };

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setFilterParams({...filterParams, [name]: value});
  };

  const handleFilters = (filtersParams) => {
    if (filtersParams.search === "") {
      setIsLoading(true);
      setFilterPagination({...filterPagination, data: {}, status: false});
      searchInventoryAccessoryForPage(1, {});
    }
    else {
      setIsLoading(true);
      setFilterPagination({...filterPagination, data: filtersParams, status: true});
      searchInventoryAccessoryForPage(1, filtersParams);
    }
  };

  const searchInventoryAccessoryForPage = (pageInventory, paramsFilters) => {
    getAllInventoryAccesory(pageInventory, 10, paramsFilters).then(res => {
      if (res.status === 200 && res.data.records.length > 0) {
        setAccessories(res.data.records);
        if (warehouseSelected !== null) {
          const filteredByWarehouse = res.data.records.filter((item) => item.warehouse._id === warehouseSelected._id);
          setAccessories(filteredByWarehouse);
        } else {
          setAccessories(res.data.records);
        }
        setPagesData({...pagesData, total: res.data.total/10, records: res.data.total});
      } else {
        toast.warning("Search no records found", alertOptions);
      }

      setIsLoading(false);
    }).catch((error) => {
      toast.warning(error.response.data.message, alertOptions);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    searchInventoryAccessoryForPage(1, {});
  }, []);


  const handleSubmit = (evt) => {
    evt.preventDefault();
    const { search} = filterParams;
    const searchQuery = {
      search: search.trim(),
    };
    handleFilters(searchQuery);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-[85%] max-h-[85%] rounded-lg bg-white p-6 overflow-y-auto">
        <div className="mb-4 flex items-center justify-between">
          <div>
          </div>

          <h2 className="text-xl font-semibold">Inventory specification</h2>

          <button
            onClick={() => onClose()}
            className="text-gray-500 hover:text-gray-700"
          >
                ✕
          </button>
        </div>
        <div className="px-6 pt-2 md:justify-center md:items-center space-x-0 space-y-3 md:space-x-3 md:space-y-0">
          <form onSubmit={handleSubmit}>
            <div className="w-full rounded-lg flex">
              <input
                type="text"
                placeholder="Search"
                name="search"
                onChange={handleChange}
                className="w-full rounded-md border border-gray-300 px-4 py-2"
              />

              <button
                className="w-[50px] bg-ag-secondary text-center text-white p-[6px] rounded-md flex justify-center"
                type="button"
                onClick={() => handleFilters(filterParams)}
              >
                <span className="text-2xl">
                  <IoMdSearch />
                </span>
              </button>

            </div>
          </form>
        </div>
        <div className="max-h-[60vh] overflow-auto mt-[30px]">
          {isLoading ?
            (
              <div className="flex justify-center items-center h-32">
                <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
                <span className="ml-4">Loading products...</span>
              </div>
            )
            :
            (
              <table className="w-full">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="border px-4 py-2">Warehouse</th>
                    <th className="border px-4 py-2">Accessory name</th>
                    <th className="border px-4 py-2">Barcodes</th>
                    <th className="border px-4 py-2">Sales Price</th>
                    <th className="border px-4 py-2">Stock</th>
                  </tr>
                </thead>
                <tbody>
                  {accessories.map((item) => {
                    const accessory = item.accessoryInventory.accessory;

                    return (
                      <tr
                        key={accessory._id}
                        onClick={() => setSelectedItem({ ...accessory, cost: item.accessoryInventory.cost, accessoryInventory: { _id: item.accessoryInventory._id } })}
                        className={`cursor-pointer transition-colors ${selectedItem && selectedItem._id === accessory._id ? 'bg-green-100' : 'hover:bg-gray-50'
                        }`}
                      >
                        <td className="border px-4 py-2">{item.warehouse.name}</td>
                        <td className="border px-4 py-2">{accessory.name}</td>
                        <td className="border px-4 py-2">{accessory.barcode1}</td>
                        <td className="border px-4 py-2">${item.accessoryInventory.cost.toFixed(2)}</td>
                        <td className="border px-4 py-2">{item.totalQuantity}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )
          }

        </div>
        <div className="mt-4 flex flex-col sm:flex-row items-center justify-end gap-4">
          <input
            type="text"
            value={quantity}
            onChange={handleQuantityChange}
            placeholder="Quantity"
            className="w-32 rounded-md border border-gray-300 px-4 py-2"
          />
          <button
            onClick={() => handleAddProduct(true)}
            className="rounded-md bg-orange-500 px-4 py-2 text-white hover:bg-orange-600"
            disabled={!selectedItem}
          >
            Bulk order
          </button>
          <button
            onClick={() => handleAddProduct(false)}
            className={`rounded-md bg-emerald-500 ${quantity > 1 ? 'opacity-50' : 'opacity-1 hover:bg-emerald-600'} px-4 py-2 text-white `}
            disabled={!selectedItem || quantity > 1}
          >
            Add product
          </button>
        </div>
      </div>
    </div>
  );
}

export default SelectAccesory;