import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { validateInventoryAdd } from "../../../../utils/Validators";
import { useInventoryContext } from "../InventoryContext";

export const useFormInventoryUtils = () => {
  const {
    productsOptions: products,
    inventoryData,
    handleSaveAccessory,
    handleEditAccessory,
    handleEditProduct,
    handleSaveProduct,
    dataSession: userData,
    newInventory: inventory,
    nagSelected,
    productSelected,
    itemsToEdit,
    action,
    accessorySelected,
    accessoriesOptions: accessories,
    tabButton
  } = useInventoryContext();

  const isAtLeastOneItemAdded = (arrItemsAdded) => {
    const isOneItemPerWarehouse = inventory.warehouses.map(warehouse => arrItemsAdded.some(element => warehouse._id === element.warehouseId));
    return isOneItemPerWarehouse.every(element => element);
  };

  const handleValidateAndSubmit = (inventoryModel) =>{
    const result = validateInventoryAdd(inventoryModel);
    if (result.status) {
      if (tabButton === "glass") {
        if (inventoryData && inventoryData !== null && action === "edit") handleEditProduct(inventoryModel);
        else handleSaveProduct(inventoryModel);
      }
      else if(tabButton === "accessory"){
        if (inventoryData && inventoryData !== null && action === "edit") handleEditAccessory(inventoryModel);
        else handleSaveAccessory(inventoryModel);
      }
    }
    else toast.warning(result.msg, alertOptions);
  };

  const handleSubmitProduct = () => {
    if (
      (action === "add" ? inventory.warehouses.length !== 0 : inventory?.warehouse !== "")
      && inventory.product !== ""
      && inventory.cost !== ""
      && inventory.maxStock !== ""
      && inventory.minStock !== ""
    ) {
      const productFound = products.find(prodSel => (prodSel.nags === nagSelected) && (prodSel.name === productSelected));
      if (!productFound) {
        toast.info("Please choose a product from the selector to find vehicle compatibility", alertOptions);
      } else {
        let rackRepeat = false;
        let rack_array = [];
        for (let i = 0; i < inventory?.racks.length; i++) {
          const foundRack = rack_array.find(element => element._id === inventory.racks[i]._id);
          if (foundRack) {
            rackRepeat = true;
            break;
          } else {
            rack_array.push(inventory.racks[i]);
          }
        }
        if (!rackRepeat) {
          if (itemsToEdit.length === 0) {
            let inventoryModel = {
              product: { _id: inventory.product },
              status: inventory.status,
              maxStock: inventory.maxStock,
              minStock: inventory.minStock,
              cost: inventory.cost,
              validateMaxMin: false
            };
            if (action === "add") {
              if (isAtLeastOneItemAdded(inventory.racks)) {
                inventory.warehouses.forEach(({_id}) => {
                  const filteredWarehouseRacks = inventory.racks.filter(rackEl => rackEl.warehouseId === _id && rackEl);
                  const finalWarehouseRacksArr = filteredWarehouseRacks.map(({ _id, quantity }) => ({ _id, quantity: Number(quantity) }));
                  inventoryModel.warehouse = { _id };
                  inventoryModel.racks = finalWarehouseRacksArr;
                  inventoryModel.by = { _id: userData._id };
                  inventoryModel.lastUpdateBy = { _id: userData._id };
                  handleValidateAndSubmit(inventoryModel);
                });
              }
              else {
                toast.info(`Please select at least one rack per warehouse`, alertOptions);
              }
            }
            else if (action === "edit") {
              const finalWarehouseRacksArr = inventory.racks.map(({ _id, quantity }) => ({ _id, quantity: Number(quantity) }));
              if (finalWarehouseRacksArr.length > 0) {
                inventoryModel.warehouse = { _id: inventory.warehouse };
                inventoryModel.racks = finalWarehouseRacksArr;
                inventoryModel._id = inventoryData.productInventory._id;
                inventoryModel.lastUpdateBy = { _id: userData._id };
                handleValidateAndSubmit(inventoryModel);
              }
              else {
                toast.info(`Please select at least one rack`, alertOptions);
              }
            }
          }
          else {
            toast.info("Please finish editing the table racks", alertOptions);
          }
        }
        else {
          toast.warning("Please, do not duplicate the racks of the table", alertOptions);
        }
      }
    } else {
      toast.info("Please fill in all the fields of the form", alertOptions);
    }
  };

  const handleSubmitAccessory = () => {
    if (
      (action === "add" ? inventory.warehouses.length > 0 : inventory?.warehouse !== "")
      && inventory.accessory !== ""
      && inventory.cost !== ""
      && inventory.maxStock !== ""
      && inventory.minStock !== ""
    ) {
      const productFound = accessories.find(prodSel => (prodSel.name === accessorySelected));
      if (!productFound) {
        toast.info("Please choose an accessory from the selector", alertOptions);
      } else {
        let sectionRepeat = false;
        let section_array = [];
        for (let i = 0; i < inventory?.sections.length; i++) {
          const foundSection = section_array.find(element => element._id === inventory.sections[i]._id);
          if (foundSection) {
            sectionRepeat = true;
            break;
          } else {
            section_array.push(inventory.sections[i]);
          }
        }
        if (!sectionRepeat) {
          if (itemsToEdit.length === 0) {
            let inventoryModel = {
              accessory: { _id: inventory.accessory },
              status: inventory.status,
              maxStock: inventory.maxStock,
              minStock: inventory.minStock,
              cost: inventory.cost,
              validateMaxMin: false
            };
            if (action === "add") {
              if (isAtLeastOneItemAdded(inventory.sections)) {
                inventory.warehouses.forEach(({ _id }) => {
                  const filteredWarehouseSections = inventory.sections.filter(sectionEl => sectionEl.warehouseId === _id && sectionEl);
                  const finalWarehouseSectionsArr = filteredWarehouseSections.map(({ _id, quantity }) => ({ _id, quantity: Number(quantity) }));
                  inventoryModel.warehouse = { _id };
                  inventoryModel.sections = finalWarehouseSectionsArr;
                  inventoryModel.by = { _id: userData._id };
                  handleValidateAndSubmit(inventoryModel);
                });
              }
              else {
                toast.info(`Please select at least one section per warehouse`, alertOptions);
              }
            }
            else if (action === "edit") {
              const finalWarehouseSectionsArr = inventory.sections.map(({ _id, quantity }) => ({ _id, quantity: Number(quantity) }));
              if (finalWarehouseSectionsArr.length > 0) {
                inventoryModel.warehouse = { _id: inventory.warehouse };
                inventoryModel.sections = finalWarehouseSectionsArr;
                inventoryModel._id = inventoryData.accessoryInventory._id;
                handleValidateAndSubmit(inventoryModel);
              }
              else {
                toast.info(`Please select at least one section`, alertOptions);
              }
            }
          } else {
            toast.info("Please finish editing the table sections", alertOptions);
          }
        } else {
          toast.warning("Please, do not duplicate the sections of the table", alertOptions);
        }
      }
    } else {
      toast.info("Please fill in all the fields of the form", alertOptions);
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (tabButton === "glass") handleSubmitProduct();
    else if (tabButton === "accessory") handleSubmitAccessory();
  };

  return {
    handleSubmit,
  };
};
