const Loader = () => {
  return (
    <>
      <div className="loading">
        <div className="spiners flex items-center justify-center z-50">
          <div className="flex items-center gap-md:[15px] md:p-5 border-0 rounded-lg shadow-lg bg-[#00000099] outline-none focus:outline-none w-[100%] ">
            <span className="loader"></span>
            <span className="text-white text-base md:text-xl">
              Loading, please wait...
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
