import { useEffect, useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import RowsOrders from "./RowsOrders";
import WithoutRows from "../../../components/table/WithoutRows";

const TableOrders = ({ enableActions, orders, currntPg, pagesData, onPage, onCancel, onAttend, onPaid, onProduct, onCompatible, onFeatures }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(4);

  useEffect(() => {
    setCurrentPage(currntPg);
  }, [currntPg]);

  return (
    <div>
      <div className="overflow-auto min-h-[38vh] lg:min-h-[44vh] 3xl:min-h-[55vh]">
        <table className="w-full text-center text-ag-secondary">
          <thead>
            <tr className="bg-ag-secondary-table">
              <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 xl:px-2 py-4">Date</th>
              <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 xl:px-2 py-4">Year chosen by client</th>
              <th className="w-[28%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-20 xl:px-2 py-4">Items</th>
              <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-8 xl:px-2 py-4">Total</th>
              <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-8 xl:px-2 py-4">Status</th>
              <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 xl:px-2 py-4">Employee</th>
              <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 xl:px-14 py-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              orders.length === 0 ? <WithoutRows colspan={6} /> : orders.map((element, i) => {
                return (
                  <RowsOrders
                    key={i}
                    enableActions={enableActions}
                    order={element}
                    onPaid={onPaid}
                    onCancel={onCancel}
                    onAttend={onAttend}
                    onProduct={onProduct}
                    onCompatible={onCompatible}
                    onFeatures={onFeatures}
                  />
                );
              })
            }
          </tbody>
        </table>
      </div>
      <div className="p-5 m-auto">
        <Pagination
          currentPage={currentPage}
          totalCount={pagesData.records}
          pageSize={recordsPerPage}
          onPageChange={page => onPage(page)}
        />
      </div>
    </div>
  );
};

export default TableOrders;