import { useEffect, useState } from "react";
import { useInventoryContext } from "../../../InventoryContext";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../../../utils/Utilities";

export const useRacksAddedUtils = ({rack, onCanEdit, onEdit, racksData}) => {
  const { rackOptions: racksOptions, isIdAlreadyAdded } = useInventoryContext();
  const [canEdit, setCanEdit] = useState(true);
  const [rackElement, setRackElement] = useState({
    warehouseId: "",
    _id: "",
    quantity: ""
  });

  useEffect(() => {
    if (rack) {
      setRackElement({...rackElement, warehouseId: rack.warehouseId, _id: rack._id, quantity: rack.quantity});
    }
  }, [rack]);

  const handleCanEdit = () => {
    if (canEdit) {
      onCanEdit(rack, true);
      setCanEdit(!canEdit);
    }
    else {
      if (rackElement.quantity === "") toast.warning("Please fill the quantity field", alertOptions);
      else {
        onCanEdit(rack, false);
        setCanEdit(!canEdit);
      }
    }
  };

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "quantity") {
      const pattern = new RegExp(/^[0-9\s]+$/g);
      if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
        setRackElement({...rackElement, [evt.currentTarget.name]: evt.currentTarget.value});
        onEdit({...rackElement, [evt.currentTarget.name]: evt.currentTarget.value});
      }
    } else {
      if (!isIdAlreadyAdded(racksData, evt.currentTarget.value)) {
        setRackElement({...rackElement, [evt.currentTarget.name]: evt.currentTarget.value});
        onEdit({...rackElement, [evt.currentTarget.name]: evt.currentTarget.value});
      }
      else {
        toast.warning("The selected rack is already added", alertOptions);
      }
    }
  };

  return {
    racksOptions,
    handleCanEdit,
    handleChange,
    canEdit,
    rackElement
  };
};
