import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { getMovementHistoryInventoryAccessory } from "../../../services/InventoryAccessory";
import { formatDate, formatCurrency } from "../../../utils/Utilities";

const MovementHistoryInventoryAccessory = ({ id, onClose }) => {
    const [activeTab, setActiveTab] = useState('generalInformation');
    const [showMessageModification, setShowMessageModification] = useState(false)
    const [movementHistoryGeneralInformation, setMovementHistoryGeneralInformation] = useState({
        added: "",
        warehouse: "",
        maxStock: "",
        minStock: "",
        quantity: "",
        by: "",
        sections: []
    });

    const [movementHistoryModification, setMovementHistoryModification] = useState({
        modificationDate: "",
        quantityAdded: "",
        newMaxStock: "",
        quantityRemoved: "",
        newMinStock: "",
        newSalesPrice: "",
        modificationBy: "",
        modifiedSections: []
    });

    useEffect(() => {
        getMovementHistoryInventoryAccessory(id).then(res => {
            if (res.status === 200) {
                setMovementHistoryGeneralInformation({
                    added: res.data.generalInformation.createdAt,
                    warehouse: res.data.generalInformation.Warehouse.name,
                    maxStock: res.data.generalInformation.maxStock,
                    minStock: res.data.generalInformation.minStock,
                    quantity: res.data.generalInformation.quantity,
                    by: res.data.generalInformation.by.names + ' ' + res.data.generalInformation.by.lastNames,
                    sections: res.data.generalInformation.sections
                })

                if (res.data.accessory !== null) {
                    setMovementHistoryModification({
                        modificationDate: res.data.accessory.updatedAt,
                        quantityAdded: res.data.accessory.totalQuantityAdded,
                        newMaxStock: res.data.accessory.maxStock,
                        quantityRemoved: res.data.accessory.totalQuantityRemoved,
                        newMinStock: res.data.accessory.minStock,
                        newSalesPrice: res.data.accessory.cost,
                        modificationBy: res.data.accessory.lastUpdateBy.names + ' ' + res.data.accessory.lastUpdateBy.lastNames,
                        modifiedSections: res.data.accessory.modifiedSections,
                    })
                    setShowMessageModification(false)
                } else {
                    setShowMessageModification(true)
                }

            }
        });
    }, [])


    return (
        <>

            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[750px] lg:w-[850px]">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none h-[500px]">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold">
                                        Movement History
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-2 md:px-5 pb-0 pt-5">
                                <div className="flex items-center w-[100%] truncate">
                                    <button onClick={() => setActiveTab('generalInformation')} className={`text-sm md:text-lg w-[25%] ${activeTab === 'generalInformation' ? "bg-[#D9D9D9] text-[#787879]" : "bg-[#FF5F52] text-[#FFF]"}`}>General information</button>
                                    <button onClick={() => setActiveTab('transfers')} className={`text-sm md:text-lg w-[25%] ${activeTab === 'transfers' ? "bg-[#D9D9D9] text-[#787879]" : "bg-[#FF5F52] text-[#FFF]"}`}>Transfers</button>
                                    <button onClick={() => setActiveTab('productModification')} className={`text-sm md:text-lg w-[25%] ${activeTab === 'productModification' ? "bg-[#D9D9D9] text-[#787879]" : "bg-[#FF5F52] text-[#FFF]"}`}>Accessory modification</button>
                                    <button onClick={() => setActiveTab('orders')} className={`text-sm md:text-lg w-[25%] ${activeTab === 'orders' ? "bg-[#D9D9D9] text-[#787879]" : "bg-[#FF5F52] text-[#FFF]"}`}>Orders</button>
                                </div>
                                {
                                    activeTab === 'generalInformation' ?
                                        <div className="mt-10">
                                            <div className="flex justify-between w-[100%] mb-8">
                                                <div className="w-[50%]">
                                                    <p className='text-sm md:text-base text-[#000] font-bold'>Date added: {formatDate(movementHistoryGeneralInformation.added)}</p>
                                                </div>
                                                <div className="w-[50%]">
                                                    <p className='text-sm md:text-base text-[#000] font-bold'>Warehouse: {movementHistoryGeneralInformation.warehouse}</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between w-[100%] mb-8">
                                                <div className="w-[50%]">
                                                    <p className='text-sm md:text-base text-[#000] font-bold'>Maximum stock:  {movementHistoryGeneralInformation.maxStock}</p>
                                                </div>
                                                <div className="w-[50%]">
                                                    <p className='text-sm md:text-base text-[#000] font-bold'>Sections:
                                                        {
                                                            movementHistoryGeneralInformation.sections.length === 0 ?
                                                                <p>---</p>
                                                                :
                                                                movementHistoryGeneralInformation.sections.map((item, index) => (
                                                                    <span key={index}>{' ' + item.rack}{index < movementHistoryGeneralInformation.sections.length - 1 && ', '}</span>
                                                                ))
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between w-[100%] mb-8">
                                                <div className="w-[50%]">
                                                    <p className='text-sm md:text-base text-[#000] font-bold'>Minimum stock: {movementHistoryGeneralInformation.minStock}</p>
                                                </div>
                                                <div className="w-[50%]">
                                                    <p className='text-sm md:text-base text-[#000] font-bold'></p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between w-[100%] mb-8">
                                                <div className="w-[50%]">
                                                    <p className='text-sm md:text-base text-[#000] font-bold'>Quantity added: {movementHistoryGeneralInformation.quantity}</p>
                                                </div>
                                                <div className="w-[50%]">
                                                    <p className='text-sm md:text-base text-[#000] font-bold'>Modified by: {movementHistoryGeneralInformation.by}</p>
                                                </div>
                                            </div>
                                        </div>
                                        : activeTab === 'transfers' ?
                                            <div className="mt-10">
                                                <p className='text-sm md:text-base text-[#000] font-bold italic'>Nothing to show.</p>
                                            </div>
                                            : activeTab === 'productModification' ?
                                                (
                                                    showMessageModification === true ? (
                                                        <div className="mt-10">
                                                            <p className='text-sm md:text-base text-[#000] font-bold italic'>Nothing to show.</p>
                                                        </div>
                                                    ) : (
                                                        <div className="mt-10">
                                                            <div className="mt-6">
                                                                <div className="flex justify-between w-[100%] mb-8">
                                                                    <div className="w-[50%]">
                                                                        <p className='text-sm md:text-base text-[#000] font-bold'>Modification date: {movementHistoryModification.modificationDate ? formatDate(movementHistoryModification.modificationDate) : ''}</p>
                                                                    </div>
                                                                    <div className="w-[50%]">
                                                                        <p className='text-sm md:text-base text-[#000] font-bold'>Quantity added: {movementHistoryModification.quantityAdded}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justify-between w-[100%] mb-8">
                                                                    <div className="w-[50%]">
                                                                        <p className='text-sm md:text-base text-[#000] font-bold'>New maximum stock:  {movementHistoryModification.newMaxStock}</p>
                                                                    </div>
                                                                    <div className="w-[50%]">
                                                                        <p className='text-sm md:text-base text-[#000] font-bold'>Quantity removed: {Math.abs(movementHistoryModification.quantityRemoved)}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justify-between w-[100%] mb-8">
                                                                    <div className="w-[50%]">
                                                                        <p className='text-sm md:text-base text-[#000] font-bold'>New minimum stock: {movementHistoryModification.newMinStock}</p>
                                                                    </div>
                                                                    <div className="w-[50%]">
                                                                        <p className='text-sm md:text-base text-[#000] font-bold'>Modified sections:
                                                                            {
                                                                                movementHistoryModification.modifiedSections.length === 0 ?
                                                                                    <p>---</p>
                                                                                    :
                                                                                    movementHistoryModification.modifiedSections.map((item, index) => (
                                                                                        <span key={index}>{' ' + item.rack}{index < movementHistoryModification.modifiedSections.length - 1 && ', '}</span>
                                                                                    ))
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justify-between w-[100%] mb-8">
                                                                    <div className="w-[50%]">
                                                                        <p className='text-sm md:text-base text-[#000] font-bold'>New sales price: {formatCurrency(movementHistoryModification.newSalesPrice)}</p>
                                                                    </div>
                                                                    <div className="w-[50%]">
                                                                        <p className='text-sm md:text-base text-[#000] font-bold'>Modified by: {movementHistoryModification.modificationBy}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                                : activeTab === 'orders' ?
                                                    <div className="mt-10">
                                                        <p className='text-sm md:text-base text-[#000] font-bold italic'>Nothing to show.</p>
                                                    </div>
                                                    :
                                                    <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
    )
}

export default MovementHistoryInventoryAccessory