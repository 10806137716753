import { useEffect, useRef, useState } from "react";
import { useInventoryContext } from "../../InventoryContext";
import { getWarehouse } from "../../../../../services/Warehouse";
import { removeAccents } from "../../../../../utils/Utilities";

export const useProductFormUtils = () => {
  const nagsRef = useRef(null);
  const namesRef = useRef(null);
  const [showNgOptions, setShowNgOptions] = useState(false);
  const [showNameOptions, setShowNameOptions] = useState(false);
  const [selOptions, setSelOptions] = useState([]);

  const {
    productsOptions: products,
    inventoryData,
    filteredWarehouseOptions,
    setFilteredWarehouseOptions,
    newInventory: inventory,
    setNewInventory: setInventory,
    nagSelected,
    setNagSelected,
    setRackOptions,
    setLoader: onLoader,
    setSumStock,
    productSelected,
    setProductSelected,
    removeSelectedWarehouse
  } = useInventoryContext();

  const handleSelect = (ngOpt, type) => {
    if (type === "nags") {
      setShowNgOptions(false);
    } else {
      setShowNameOptions(false);
    }

    const productFound = products.find(productElement => productElement._id === ngOpt);
    if (productFound) {
      setNagSelected(productFound.nags);
      setProductSelected(productFound.name);
      setInventory({...inventory,
        product: type === "nags" ? ngOpt : productFound._id,
        nags: type === "nags" ? productFound.nags : ngOpt,
        nagPrice: productFound.price
      });
    }
  };

  const handleDownSelect = (type) => {
    if (type === "product") {
      setShowNameOptions(!showNameOptions);
      setShowNgOptions(false);
    } else {
      setShowNgOptions(!showNgOptions);
      setShowNameOptions(false);
    }
  };

  const handleChangeSel = (evt, type) => {
    if (type === "nags") {
      setNagSelected(evt.target.value);
    } else {
      setProductSelected(evt.target.value);
    }

    if (evt.target.value === "") {
      const filterbyStatus = products.filter((item) => item.status !== "inactivated").sort((a, b) => (new Date(b.lastUpdate) - new Date(a.lastUpdate)));
      setSelOptions(filterbyStatus);
      setInventory({...inventory, product: "", nags: "", nagPrice: "" });
    } else {
      let palabra = new RegExp(`${removeAccents(evt.target.value)}.*`, "i");
      const newNagsOpts = products.filter(elem => elem.status !== "inactivated" && (palabra.test(elem?.name) || palabra.test(elem?.nags)));
      setSelOptions(newNagsOpts);
    }
  };

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "warehouse") {
      const selectedWarehouse = filteredWarehouseOptions.find(({ _id }) => _id === evt.currentTarget.value);
      if (selectedWarehouse) {
        setFilteredWarehouseOptions(filteredWarehouseOptions.filter(({ _id }) => _id !== evt.currentTarget.value));
        const { _id, name } = selectedWarehouse;
        const customWarehouseOption = { _id, name, selected: true };
        setInventory({ ...inventory, warehouses: [...inventory.warehouses, customWarehouseOption] });
        getWarehouse(evt.currentTarget.value).then(res => {
          if (res.status === 200) {
            const racksPerWarehouse = res.data.racks.map(rack => ({warehouseId: _id, ...rack}));
            setRackOptions(prevState => [...prevState, ...racksPerWarehouse]);
          }
        });
        evt.currentTarget.value = "";
      }
    } else {
      if (evt.currentTarget.name === "cost" /*|| evt.currentTarget.name === "sellerPrice" || evt.currentTarget.name === "resellerPrice"*/) {
        const pattern = new RegExp(/^\d*\.?\d*$/);
        if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
          setInventory({...inventory, [evt.currentTarget.name]: evt.currentTarget.value});
        }
      } else if (evt.currentTarget.name === "maxStock" || evt.currentTarget.name === "minStock") {
        const pattern = new RegExp(/^[0-9\s]+$/g);
        if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
          setInventory({...inventory, [evt.currentTarget.name]: evt.currentTarget.value});
        }
      } else {
        setInventory({...inventory, [evt.currentTarget.name]: evt.currentTarget.value});
      }
    }
  };

  const handleClickOutside = (event) => {
    if (nagsRef.current && !nagsRef.current.contains(event.target)) {
      setShowNgOptions(false);
    }

    if (namesRef.current && !namesRef.current.contains(event.target)) {
      setShowNameOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    if (inventoryData) {
      onLoader(true);
      getWarehouse(inventoryData.warehouse._id).then(resWare => {
        if (resWare.status === 200) {
          setRackOptions(resWare.data.racks);
        }

        let racksAux = [];
        let stockAcum = 0;
        if (inventoryData.racks.length > 0) {
          racksAux = inventoryData.racks.map((element) => {
            stockAcum += Number(element.quantity);
            return { _id: element.rack._id, quantity: element.quantity };
          });
        }

        setSumStock(stockAcum);
        setProductSelected(inventoryData.productInventory.product.name);
        setNagSelected(inventoryData.productInventory.product.nags);
        setInventory({...inventory,
          status: inventoryData.productInventory.status,
          warehouse: inventoryData.warehouse._id,
          product: inventoryData.productInventory.product._id,
          nags: inventoryData.productInventory.product.nags,
          cost: inventoryData.productInventory.cost,
          nagPrice: inventoryData?.productInventory?.product?.price,
          //sellerPrice: inventoryData.productInventory.sellerPrice,
          //resellerPrice: inventoryData.productInventory.resellerPrice,
          maxStock: inventoryData.productInventory.maxStock,
          minStock: inventoryData.productInventory.minStock,
          stock: stockAcum,
          racks: racksAux
        });
        onLoader(false);
      }).catch(() => onLoader(false));
    } else {
      const filterbyStatus = products.filter((item) => item.status !== "inactivated").sort((a, b) => (new Date(b.lastUpdate) - new Date(a.lastUpdate)));
      setSelOptions(filterbyStatus);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inventoryData, products]);

  return {
    nagsRef,
    inventoryData,
    handleChangeSel,
    nagSelected,
    setShowNgOptions,
    handleDownSelect,
    showNgOptions,
    selOptions,
    handleSelect,
    handleChange,
    inventory,
    filteredWarehouseOptions,
    namesRef,
    showNameOptions,
    setShowNameOptions,
    productSelected,
    removeSelectedWarehouse,
    products
  };
};

