import { useEffect, useState } from "react";
import { useInventoryContext } from "../../../InventoryContext";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../../../utils/Utilities";

export const useSectionsAddedUtils = ({section, onCanEdit, onEdit, sectionsData}) => {
  const { sectionOptions } = useInventoryContext();
  const [canEdit, setCanEdit] = useState(true);
  const [sectionElement, setSectionElement] = useState({
    warehouseId: "",
    _id: "",
    quantity: ""
  });

  useEffect(() => {
    if (section) {
      setSectionElement({...sectionElement, warehouseId: section.warehouseId, _id: section._id, quantity: section.quantity});
    }
  }, [section]);

  const handleCanEdit = () => {
    if (canEdit) {
      onCanEdit(section, true);
      setCanEdit(!canEdit);
    }
    else {
      if (sectionElement.quantity === "") toast.warning("Please fill the quantity field", alertOptions);
      else {
        onCanEdit(section, false);
        setCanEdit(!canEdit);
      }
    }
  };

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "quantity") {
      const pattern = new RegExp(/^[0-9\s]+$/g);
      if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
        setSectionElement({...sectionElement, [evt.currentTarget.name]: evt.currentTarget.value});
        onEdit({...sectionElement, [evt.currentTarget.name]: evt.currentTarget.value});
      }
    } else {
      const isSectionAdded = sectionsData.some(section => section._id === evt.currentTarget.value);
      if (!isSectionAdded) {
        setSectionElement({...sectionElement, [evt.currentTarget.name]: evt.currentTarget.value});
        onEdit({...sectionElement, [evt.currentTarget.name]: evt.currentTarget.value});
      }
      else {
        toast.warning("The selected section is already added", alertOptions);
      }
    }
  };

  return {
    sectionOptions,
    handleCanEdit,
    handleChange,
    canEdit,
    sectionElement
  };
};
