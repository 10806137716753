import { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import FormInventory from "./FormInventory";
import Loader from "../../../../components/loader/Loader";
import { InventoryContextProvider, useInventoryContext } from "../InventoryContext";
import EditFormInventory from "./EditFormInventory";
import ModalCompatible from "../../products/ModalCompatible";
import EditAccessoryFormInventory from "./EditAccessoryFormInventory";

export const SaveInventoryLayout = ({ product = "product" }) => {
  return (
    <InventoryContextProvider>
      <SaveInventory product={product} />
    </InventoryContextProvider>
  );
};

const SaveInventory = ({ product = "product" }) => {
  const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
  const {
    tabButton,
    handleChangeTabButton,
    linkTo,
    loader,
    action
  } = useInventoryContext();

  useEffect(() => {
    if (product) {
      if(product === 'product') handleChangeTabButton('glass');
      else if (product === 'accessory') handleChangeTabButton('accessory');
    }
  }, [product]);

  const defaultTabButton = tabButton === "glass";

  return (
    <>
      {loader && <Loader />}

      {action === "add" ?
        (
          <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
            <div className="flex gap-2 items-center">
              <Link to={linkTo}>
                <button className="rounded-md" type="button">
                  <span className="text-ag-secondary-dark text-2xl md:text-3xl"><FiChevronLeft /></span>
                </button>
              </Link>
              <h1 className="text-[26px] font-bold text-ag-secondary-dark md:mr-[8%]">
                Add {defaultTabButton ? "Product" : "Accessory"}
              </h1>
            </div>
            <div className="py-3 grid grid-cols-1 grid-rows-1 min-h-[78dvh]">
              <FormInventory/>
            </div>
          </div>
        )
        :
        (
          <>
            <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
              <div className="grid grid-cols-12 px-0 md:px-5">
                <Link to={linkTo}>
                  <button className="p-1 rounded-md" type="button">
                    <span className="text-ag-secondary-dark text-2xl md:text-3xl"><FiChevronLeft /></span>
                  </button>
                </Link>
                <div className="flex justify-center items-center col-span-11 text-center">
                  <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                    Edit {defaultTabButton ? "Product" : "Accessory"}
                  </h1>
                </div>
              </div>
              <div className="py-3">
                {defaultTabButton ?
                  <EditFormInventory
                    onCompatible={(prdSel) => setShowCompatible({ ...showCompatible, status: true, element: prdSel })}
                  />
                  :
                  <EditAccessoryFormInventory/>
                }
              </div>
            </div>

            {showCompatible.status && (
              <ModalCompatible
                products={showCompatible.element}
                onClose={() => setShowCompatible({ ...showCompatible, status: false, element: null })}
              />
            )}
          </>
        )
      }




    </>
  );
};

export default SaveInventoryLayout;
