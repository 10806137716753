import { BsCaretDownFill } from "react-icons/bs";
import { useFormInventoryUtils } from "./useFormInventoryUtils";
import { useInventoryContext } from "../InventoryContext";
import { useAccessoryFormUtils } from "./accessories/useAccessoryFormUtils";
import { useSectionsFormUtils } from "./accessories/sections/useSectionsFormUtils";
import TableInventoryAccessory from "../../inventoryAccessories/saveInventoryAccessory/TableInventoryAccessory";

const EditAccessoryFormInventory = () => {
  const {
    sectionOptions: sectionsOptions,
    sumStock,
  } = useInventoryContext();

  const {
    handleSubmit,
  } = useFormInventoryUtils();

  const {
    accessoriesRef,
    inventoryData,
    setShowNgOptions,
    setShowNameOptions,
    handleChange,
    inventory,
    filteredWarehouseOptions: warehouses,
    accessorySelected,
  } = useAccessoryFormUtils();

  const {
    handleSubmit: addSection,
    editSection,
    deleteSection,
    handleCanEdit
  } = useSectionsFormUtils({});

  const handleCloseSelect = () => {
    setShowNgOptions(false);
    setShowNameOptions(false);
  };

  return (
    <div>
      <form className="space-y-10" onSubmit={handleSubmit}>
        <div className="grid gap-3 md:grid-cols-2 lg:grid-cols-3">
          <div className="w-full flex justify-center items-center">
            <span
              className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
            >
              Accessory Name
            </span>
            <div className="w-[60%] relative" ref={accessoriesRef}>
              <div className={`w-full flex justify-center items-center ${ inventoryData && "bg-zinc-200" } text-ag-secondary-letter border border-gray-500 rounded-r-xl truncate`}>
                <input
                  className="w-full p-2 xl:p-3 text-left text-ag-secondary-letter disabled:bg-zinc-200 truncate over"
                  name="accessory"
                  autoComplete="off"
                  value={accessorySelected}
                  disabled={inventoryData}
                />
                <span className="text-ag-secondary-letter px-2">
                  <BsCaretDownFill />
                </span>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center items-center">
            <span
              className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
            >
              Warehouse
            </span>
            <div className="relative w-[60%]">
              <select
                className="text-ag-secondary-letter border border-gray-500 px-2 xl:px-3 py-2 xl:py-[13px] rounded-r-xl disabled:bg-zinc-200 disabled:opacity-100 truncate w-full"
                id="select-inventoryAdd-warehouse"
                name="warehouse"
                onChange={handleChange}
                value={inventory.warehouse}
                disabled={inventoryData}
              >
                <option value="default"></option>
                {warehouses.map((element, index) => (
                  <option key={index} value={element._id}>{element.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="w-full flex justify-center items-center">
            <span
              className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
            >
              Accesory Price
            </span>
            <input
              className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl disabled:bg-zinc-200 truncate"
              type="text"
              name="nagPrice"
              value={inventory.accessoryPrice}
              disabled={true}
            />
          </div>
          <div className="w-full flex justify-center items-center">
            <span
              className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
            >
              Sales Price
            </span>
            <input
              className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
              type="text"
              name="cost"
              value={inventory.cost}
              onChange={handleChange}
              maxLength={12}
            />
          </div>
          <div className="w-full flex justify-center items-center">
            <span
              className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
            >
              Maximum Stock
            </span>
            <input
              className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
              type="text"
              name="maxStock"
              value={inventory.maxStock}
              onChange={handleChange}
              maxLength={12}
            />
          </div>
          <div className="w-full flex justify-center items-center">
            <span
              className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
            >
              Minimum Stock
            </span>
            <input
              className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
              type="text"
              name="minStock"
              value={inventory.minStock}
              onChange={handleChange}
              maxLength={12}
            />
          </div>
          <div className="w-full flex justify-center items-center">
            <span
              className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
            >
              Stock
            </span>
            <input
              className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl disabled:bg-zinc-200 truncate"
              type="text"
              name="stock"
              value={inventory.stock}
              disabled={true}
              maxLength={12}
            />
          </div>

        </div>
        <div>
          <div className="md:flex md:justify-center md:items-center border-t border-ag-secondary py-6 space-y-3 md:space-y-0">
            <div className="w-full md:w-[66%] text-center md:text-right px-0 md:px-[10%] pb-5 md:pb-0">
              <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Organize by sections</h1>
            </div>
            <div className="w-full md:w-[34%]">
              <button
                className="w-full bg-ag-primary text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
          <div className="px-5 md:px-20 py-5 md:py-10 border border-ag-secondary-light rounded-lg">
            <TableInventoryAccessory
              type="transfer"
              maxStock={inventory.maxStock}
              sumStock={sumStock}
              sectionsCurrent={inventory.sections}
              sections={sectionsOptions}
              onSave={addSection}
              onEdit={editSection}
              onDelete={deleteSection}
              onCloseSelect={handleCloseSelect}
              onCanEdit={handleCanEdit}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditAccessoryFormInventory;