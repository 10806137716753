import { useInventoryContext } from "../InventoryContext";
import SectionItem from "./accessories/sections/SectionItem";
import RackItem from "./products/racks/RackItem";

const WarehousesItemsForm = () => {
  const {
    newInventory: inventory,
    tabButton,
    sortWarehouses
  } = useInventoryContext();

  const defaultTabButton = tabButton === "glass";

  return (
    <>
      <h1 className="flex pt-8 pb-2 px-0 text-2xl font-bold text-ag-secondary-light">Select {defaultTabButton ? "rack" : "section" }</h1>
      <div className="grid grid-cols-12 gap-4 overflow-x-auto md:overflow-x-hidden">
        {inventory.warehouses.sort((warehouseA, warehouseB) => sortWarehouses(warehouseA, warehouseB)).map(warehouse =>
          defaultTabButton ?
            <RackItem key={warehouse._id} warehouse={warehouse} />
            :
            <SectionItem key={warehouse._id} warehouse={warehouse}/>
        )}
      </div>
    </>

  );
};

export default WarehousesItemsForm;