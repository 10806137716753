import { AiOutlineDelete } from "react-icons/ai";
import { BsImage } from "react-icons/bs";
import { formatCurrency } from "../../../../../utils/Utilities";
import { useEffect, useState } from "react";

const RowsSentProducts = ({ product, onProduct, onDelete, onCompatible }) => {
  const [valQty, setValQty] = useState(0);

  const handleChange = (evt) => {
    if (evt.target.name === "quantity") {
      setValQty(evt.target.value);
      onProduct(evt, product);
      // const pattern = new RegExp(/^[0-9\s]+$/g);
      // if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
      //     if (evt.target.value <= product?.data?.quantity) {
      //         setValQty(evt.target.value);
      //         onProduct(evt, product);
      //     } else {
      //         toast.warning("You cannot send more products than the one available in stock", alertOptions);
      //     }
      // }
    }
  };

  useEffect(() => {
    if (product?.quantity) setValQty(product?.quantity);
  }, [product?.quantity]);


  return (
    <tr>
      <td className="w-[40%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
        <div className="w-full flex justify-center items-center space-x-2">
          <div className="w-[20%] flex justify-center items-center">
            { product.data.productInventory.product?.productImages[0] ?
              <div className="w-[45px] h-[45px] flex justify-center items-center border border-ag-secondary-light rounded-lg p-1">
                <img className="w-full h-full object-contain" src={product.data.productInventory.product.productImages[0]} />
              </div>
              :
              <span className="text-4xl"><BsImage /></span>
            }
          </div>
          <div className="w-[80%] text-left text-[10px]">
            <div className="space-y-[-3px]">
              <div>
                <span>{ product.data.productInventory.product.nags }</span>
                <span>{' - '}</span>
                <span>{ product.data.productInventory.product.name }</span>
              </div>
              <div>
                {
                  product.data.productInventory.product.compatible_vehicles.map((prodComp, i) => {
                    if (i <= 2) {
                      return (
                        <p className="truncate w-32 md:w-44 xl:w-60" key={i} title={
                          `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                          `${prodComp?.brand} ` +
                          `${prodComp?.model} ` +
                          `${prodComp?.type_car}`
                        }>
                          { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                          { prodComp?.brand }{' '}
                          { prodComp?.model }{' '}
                          { prodComp?.type_car }
                        </p>
                      );
                    }
                  })
                }
                {
                  product.data.productInventory.product.compatible_vehicles.length > 3 ?
                    <p className="truncate w-32 md:w-44 xl:w-60 font-semibold cursor-pointer" onClick={() => onCompatible(product.data.productInventory.product)}>
                      See compatible vehicles +
                    </p>
                    : null
                }
              </div>
            </div>
            <div className="space-y-[-3px]">
              <div>
                <span className="font-bold">Qty in stock at Warehouse: { product?.data?.sumQuantity }</span>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
        { product.data.rack ? product.data.rack.name : product.rack.name }
      </td>
      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
        <input
          className={`w-full h-10 text-center ${valQty <= product?.data?.sumQuantity ? "border-2 border-green-500" : "border-2 border-red-500"} rounded-md`}
          name="quantity"
          type="number"
          min="0"
          onChange={(e) => {
            const value = e.target.value;
            if ((Number.isInteger(Number(value)) && Number(value) >= 0) || value === '') {
              handleChange(e);
            }
          }}
          onKeyDown={(e) => {
            if (['.', ',', '-', 'e'].includes(e.key)) {
              e.preventDefault();
            }
          }}
          value={valQty}
        />
      </td>
      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-left text-xs font-normal p-2">{ formatCurrency(product.defaultCost) }</td>
      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
        <div className="w-full flex justify-between items-center">
          <span>{ product.quantity ? formatCurrency(product.quantity * product.defaultCost) : formatCurrency(0) }</span>
          <button type="button" onClick={() => onDelete(product)}>
            <span className="text-red-600 text-4xl">
              <AiOutlineDelete />
            </span>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default RowsSentProducts;