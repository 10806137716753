import { useState } from "react";
import { useRacksFormUtils } from "./useRacksFormUtils";
import RacksAdded from "./RacksAdded";
import { HiPlusCircle } from "react-icons/hi";

const RackItem = ({ warehouse }) => {
  const [rackData, setRackData] = useState({
    warehouseId: "",
    _id: "",
    quantity: "",
    name: ""
  });

  const {
    racksData,
    editRack,
    deleteRack,
    handleCanEdit,
    handleChange,
    rackOptions,
    handleSubmit,
  } = useRacksFormUtils({rackData, setRackData});

  return (
    <div className="w-full block col-span-12 xl:col-span-6">
      <ul className="list-disc col-span-12">
        <li className="ml-4 w-full text-ag-secondary">{warehouse.name}</li>
      </ul>
      <div className="w-full px-0 flex md:grid md:grid-cols-12 md:col-span-12 gap-2 items-center">
        <div className="block md:col-span-5">
          <div className="w-full flex justify-center items-center">
            <span
              className="min-w-[8rem] md:w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
            >
              Rack
            </span>

            <select
              className="w-[15rem] md:w-[60%] text-ag-secondary-letter border border-gray-500 px-2 xl:px-3 py-2 xl:py-[13px] rounded-r-xl disabled:bg-zinc-200 disabled:opacity-100 truncate"
              name="_id"
              onChange={handleChange}
              value={rackData._id}
            >
              <option value="default"></option>
              {
                rackOptions.map((element, index) => element.warehouseId === warehouse._id &&
                    <option key={index} value={element._id}>{element.name}</option>
                )
              }
            </select>
          </div>
        </div>
        <div className="block md:col-span-5">
          <div className="w-full flex justify-center items-center">
            <span
              className="min-w-[8rem] md:w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
            >
              Quantity
            </span>
            <input
              className="w-[15rem] md:w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
              name="quantity"
              type="text"
              onChange={handleChange}
              value={rackData.quantity}
              maxLength={10}
            />
          </div>
        </div>
        <div className="md:col-span-1">
          <button type="button" className=" text-white rounded-full flex items-center justify-center" onClick={() => handleSubmit(warehouse._id)}>
            <span className="text-green-600 text-[30px]"><HiPlusCircle /></span>
          </button>
        </div>
      </div>
      {
        racksData.length > 0 &&
        <div className="w-full flex flex-col pt-10 gap-2 place-content-center">
          {
            racksData.map((element, i) => element.warehouseId === warehouse._id &&
              (
                <RacksAdded
                  key={i}
                  currentWarehouse={warehouse._id}
                  rack={element}
                  onEdit={(rackModel) => editRack(i, rackModel)}
                  onDelete={deleteRack}
                  onCanEdit={handleCanEdit}
                  racksData={racksData}
                />
              ))
          }
        </div>
      }
    </div>
  );
};

export default RackItem;