import { MdEdit } from "react-icons/md";
import { BsCheckCircleFill } from "react-icons/bs";
import { RiCloseCircleFill } from "react-icons/ri";
import { useSectionsAddedUtils } from "./useSectionsAddedUtils";

const SectionsAdded = ({ currentWarehouse, section, onEdit, onDelete, onCanEdit, sectionsData }) => {
  const { sectionOptions, handleCanEdit, handleChange, canEdit, sectionElement } = useSectionsAddedUtils({ section, onCanEdit, onEdit, sectionsData });
  return (
    <div className="flex md:grid md:grid-cols-12 md:col-span-12 gap-2">
      <div className=" md:col-span-5 flex justify-center">
        <select
          className="min-w-[12rem] text-ag-secondary-letter border border-gray-500 px-2 xl:px-3 py-2 xl:py-[13px] rounded-md disabled:bg-zinc-200 disabled:opacity-100 truncate"
          name="_id"
          onChange={handleChange}
          value={sectionElement._id}
          disabled={canEdit}
        >
          {
            sectionOptions.map((element, index) => element.warehouseId === currentWarehouse &&
               <option key={index} value={element._id}>{element.name}</option>
            )
          }
        </select>
      </div>
      <div className="w-full md:col-span-5 flex justify-center">
        <input
          className="text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-md truncate disabled:bg-zinc-200 disabled:opacity-100"
          name="quantity"
          type="text"
          onChange={handleChange}
          defaultValue={sectionElement.quantity}
          value={sectionElement.quantity}
          disabled={canEdit}
        />
      </div>
      <div className="flex md:col-span-2 items-center justify-start">
        <button type="button" onClick={() => handleCanEdit()}>
          <span className={`${ canEdit ? "text-3xl" : "text-2xl text-green-600" } flex`}>
            {
              canEdit ? <MdEdit /> : <BsCheckCircleFill />
            }
          </span>
        </button>
        <button type="button" onClick={() => onDelete(sectionElement._id)}>
          <span className="text-3xl text-red-700"><RiCloseCircleFill /></span>
        </button>
      </div>
    </div>
  );
};

export default SectionsAdded;