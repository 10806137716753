import { useState, useEffect } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";

const RowsSection = ({ section, sectionsOptions, onEdit, onDelete, onCanEdit }) => {
  const [canEdit, setCanEdit] = useState(true);
  const [sectionElement, setSectionElement] = useState({
    _id: "",
    quantity: "",
  });

  const handleCanEdit = () => {
    if (canEdit) {
      onCanEdit(section, true);
    } else {
      onCanEdit(section, false);
    }
    setCanEdit(!canEdit);
  };

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "quantity") {
      const pattern = new RegExp(/^[0-9\s]+$/g);
      if (
        pattern.test(evt.currentTarget.value) ||
        evt.currentTarget.value === ""
      ) {
        setSectionElement({
          ...sectionElement,
          [evt.currentTarget.name]: evt.currentTarget.value,
        });
        onEdit({
          ...sectionElement,
          [evt.currentTarget.name]: evt.currentTarget.value,
        });
      }
    } else {
      setSectionElement({
        ...sectionElement,
        [evt.currentTarget.name]: evt.currentTarget.value,
      });
      onEdit({
        ...sectionElement,
        [evt.currentTarget.name]: evt.currentTarget.value,
      });
    }
  };

  useEffect(() => {
    if (section) {
      setSectionElement({
        ...sectionElement,
        _id: section._id,
        quantity: section.quantity,
      });
    }
  }, [section]);
  return (
    <tr>
      <td className="w-[33%] p-5 border-[0.5px] border-ag-secondary-light">
        <select
          className="w-full text-center text-ag-secondary-letter text-xs border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200"
          name="_id"
          onChange={handleChange}
          value={sectionElement._id}
          disabled={canEdit}
        >
          {sectionsOptions.map((element, index) => {
            return (
              <option key={index} value={element._id}>
                {element.name}
              </option>
            );
          })}
        </select>
      </td>
      <td className="w-[33%] p-5 border-[0.5px] border-ag-secondary-light">
        <input
          className="w-full text-center text-ag-secondary-letter text-xs border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200"
          name="quantity"
          type="text"
          onChange={handleChange}
          defaultValue={sectionElement.quantity}
          value={sectionElement.quantity}
          disabled={canEdit}
        />
      </td>
      <td className="w-[33%] p-5 border-[0.5px] border-ag-secondary-light">
        <div className="flex justify-center items-center space-x-10">
          <div>
            <button type="button" onClick={() => handleCanEdit()}>
              <span
                className={`${
                  canEdit ? "text-3xl" : "text-2xl text-green-600"
                }`}
              >
                {canEdit ? <MdEdit /> : <BsCheckCircleFill />}
              </span>
            </button>
          </div>
          <div>
            <button type="button" onClick={() => onDelete(sectionElement._id)}>
              <span className="text-3xl text-red-700">
                <RiCloseCircleFill />
              </span>
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default RowsSection;
