import { useEffect, useRef, useState } from "react";
import { useInventoryContext } from "../../InventoryContext";
import { getWarehouseSections } from "../../../../../services/Warehouse";
import { removeAccents } from "../../../../../utils/Utilities";

export const useAccessoryFormUtils = () => {
  const accessoriesRef = useRef(null);
  const namesRef = useRef(null);
  const [showNgOptions, setShowNgOptions] = useState(false);
  const [showNameOptions, setShowNameOptions] = useState(false);
  const [selOptions, setSelOptions] = useState([]);

  const {
    accessorySelected,
    accessoriesOptions: accessories,
    inventoryData,
    filteredWarehouseOptions,
    setFilteredWarehouseOptions,
    newInventory: inventory,
    setNewInventory: setInventory,
    setLoader: onLoader,
    setSumStock,
    setAccessorySelected,
    setSectionOptions,
    removeSelectedWarehouse
  } = useInventoryContext();

  const handleSelect = (ngOpt, type) => {
    if (type === "accessory") {
      setShowNgOptions(false);
    } else {
      setShowNameOptions(false);
    }

    const productFound = accessories.find(productElement => productElement._id === ngOpt);
    if (productFound) {
      setAccessorySelected(productFound.name);
      setInventory({...inventory,
        accessory: type === "accessory" ? ngOpt : productFound._id,
        name: type === "accessory" ? productFound.nags : ngOpt,
        accessoryPrice: productFound.price
      });
    }
  };

  const handleDownSelect = (type) => {
    if (type === "product") {
      setShowNameOptions(!showNameOptions);
      setShowNgOptions(false);
    } else {
      setShowNgOptions(!showNgOptions);
      setShowNameOptions(false);
    }
  };

  const handleChangeSel = (evt, type) => {
    if (type === "accessory") {
      setAccessorySelected(evt.target.value);
    }

    if (evt.target.value === "") {
      const filterbyStatus = accessories.filter((item) => item.status === true).sort((a, b) => (new Date(b.updatedAt) - new Date(a.updatedAt)));
      setSelOptions(filterbyStatus);
      setInventory({ ...inventory, accessory: "", name: "", accessoryPrice: "" });
    } else {
      let palabra = new RegExp(`${removeAccents(evt.target.value)}.*`, "i");
      const newAccessoryOpts = accessories.filter(elem => elem.status === true && (palabra.test(elem?.name)));
      setSelOptions(newAccessoryOpts);
    }
  };

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "warehouse") {
      const selectedWarehouse = filteredWarehouseOptions.find(({ _id }) => _id === evt.currentTarget.value);
      if (selectedWarehouse) {
        setFilteredWarehouseOptions(filteredWarehouseOptions.filter(({ _id }) => _id !== evt.currentTarget.value));
        const { _id, name } = selectedWarehouse;
        const customWarehouseOption = { _id, name, selected: true };
        setInventory({ ...inventory, warehouses: [...inventory.warehouses, customWarehouseOption] });
        getWarehouseSections(evt.currentTarget.value).then(res => {
          if (res.status === 200) {
            const sectionsPerWarehouse = res.data.map(section => ({ warehouseId: _id, ...section }));
            setSectionOptions(prevState => [...prevState, ...sectionsPerWarehouse]);
          }
        });
        evt.currentTarget.value = "";
      }
    } else {
      if (evt.currentTarget.name === "cost" /*|| evt.currentTarget.name === "sellerPrice" || evt.currentTarget.name === "resellerPrice"*/) {
        const pattern = new RegExp(/^\d*\.?\d*$/);
        if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
          setInventory({...inventory, [evt.currentTarget.name]: evt.currentTarget.value});
        }
      } else if (evt.currentTarget.name === "maxStock" || evt.currentTarget.name === "minStock") {
        const pattern = new RegExp(/^[0-9\s]+$/g);
        if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
          setInventory({...inventory, [evt.currentTarget.name]: evt.currentTarget.value});
        }
      } else {
        setInventory({...inventory, [evt.currentTarget.name]: evt.currentTarget.value});
      }
    }
  };

  const handleClickOutside = (event) => {
    if (accessoriesRef.current && !accessoriesRef.current.contains(event.target)) {
      setShowNgOptions(false);
    }

    if (namesRef.current && !namesRef.current.contains(event.target)) {
      setShowNameOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    if (inventoryData) {
      onLoader(true);
      getWarehouseSections(inventoryData.warehouse._id).then(resWare => {
        if (resWare.status === 200) {
          setSectionOptions(resWare.data);
        }

        let sectionsAux = [];
        let stockAcum = 0;
        if (inventoryData.sections.length > 0) {
          sectionsAux = inventoryData.sections.map((element) => {
            stockAcum += Number(element.quantity);
            return { _id: element.section._id, quantity: element.quantity };
          });
        }

        setSumStock(stockAcum);
        setAccessorySelected(inventoryData.accessoryInventory.accessory.name);
        setInventory({...inventory,
          status: inventoryData.accessoryInventory.status,
          warehouse: inventoryData.warehouse._id,
          accessory: inventoryData.accessoryInventory.accessory._id,
          name: inventoryData.accessoryInventory.accessory.name,
          cost: inventoryData.accessoryInventory.cost,
          accessoryPrice: inventoryData?.accessoryInventory?.accessory?.price,
          maxStock: inventoryData.accessoryInventory.maxStock,
          minStock: inventoryData.accessoryInventory.minStock,
          stock: stockAcum,
          sections: sectionsAux
        });
        onLoader(false);
      }).catch(() => onLoader(false));
    } else {
      const filterbyStatus = accessories.filter((item) => item.status === true).sort((a, b) => (new Date(b.updatedAt) - new Date(a.updatedAt)));
      setSelOptions(filterbyStatus);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inventoryData, accessories]);

  return {
    accessorySelected,
    accessoriesRef,
    inventoryData,
    handleChangeSel,
    setShowNgOptions,
    handleDownSelect,
    showNgOptions,
    selOptions,
    handleSelect,
    handleChange,
    inventory,
    filteredWarehouseOptions,
    namesRef,
    showNameOptions,
    setShowNameOptions,
    removeSelectedWarehouse,
    accessories,
  };
};

