import { useEffect, useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import RowsSection from "./RowsSection";

const TableInventoryAccessory = ({ type, maxStock, sumStock, sectionsCurrent, sections, onSave, onEdit, onDelete, onCloseSelect, onCanEdit }) => {
    const [availableProducts, setAvailableProducts] = useState(0);
    const [sectionsData, setSectionsData] = useState([]);
    const [sectionData, setSectionData] = useState({
      _id: "",
      quantity: "",
      name: ""
    });
  
    const handleChange = (evt) => {
      if (evt.target.name === "_id" && type === "order") {
        const foundSection = sections.find(
          (eleSect) => eleSect._id === evt.target.value
        );
        if (foundSection) setAvailableProducts(foundSection.quantityAvailable);
      }
      if (evt.currentTarget.name === "quantity") {
        const pattern = new RegExp(/^[0-9\s]+$/g);
        if (
          pattern.test(evt.currentTarget.value) ||
          evt.currentTarget.value === ""
        ) {
          setSectionData({
            ...sectionData,
            [evt.currentTarget.name]: evt.currentTarget.value,
          });
        }
      } else {
        const foundSection = sections.find(
          (eleRck) => eleRck._id === evt.target.value
        );
        setSectionData({
          ...sectionData,
          [evt.currentTarget.name]: evt.currentTarget.value,
          name: foundSection ? foundSection.name : "",
        });
      }
    };

    const handleSubmit = () => {
      onCloseSelect(true);
      if (type === "transfer") {
        if (maxStock === "" || maxStock === 0) {
          toast.warning("Please fill in the maximum stock field", alertOptions);
        } else {
          //Se quita validacion de minimos y máximos al editar un producto del inventario de accesorios
          // if (Number(sumStock) + Number(sectionData.quantity) > Number(maxStock)) {
          //   toast.warning(
          //     "You cannot add more products than the established maximum in stock",
          //     alertOptions
          //   );
          // } else {
          //   onSave(sectionData);
          //   setSectionData({ ...sectionData, _id: "", quantity: "", name: "" });
          // }
          onSave(sectionData);
          setSectionData({ ...sectionData, _id: "", quantity: "", name: "" });
        }
      } else if (sectionData.quantity <= availableProducts) {
        onSave(sectionData);
        setSectionData({ ...sectionData, _id: "", quantity: "", name: "" });
        setAvailableProducts(0);
      } else {
        toast.warning(
          `You cannot allocate a quantity greater than ${availableProducts} in this section`,
          alertOptions
        );
      }
    };
  
    useEffect(() => {
      setSectionsData(sectionsCurrent);
    }, [sectionsCurrent]);
  return (
    <div>
      <div className="px-16 w-full md:flex md:justify-between md:items-end space-x-0 md:space-x-10 space-y-3 md:space-y-0">
        <div className="w-full block">
          <div className="w-full text-center pb-1">
            <span className="text-xs lg:text-sm text-ag-secondary">Section</span>
          </div>
          <div className="w-full">
            <select
              className="w-full text-center text-ag-secondary-letter text-xs border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
              name="_id"
              onChange={handleChange}
              value={sectionData._id}
              onFocus={onCloseSelect}
            >
              <option value="default"></option>
              {sections.map((element, index) => {
                return (
                  <option key={index} value={element._id}>
                    {element.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {type === "order" ? (
          <div className="w-full block">
            <div className="w-full text-center pb-1">
              <span className="text-xs lg:text-sm text-ag-secondary">
                Available
              </span>
            </div>
            <div className="w-full">
              <input
                className="w-full text-center text-ag-secondary-letter text-xs border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                type="text"
                name="availableProducts"
                value={availableProducts}
                disabled={true}
              />
            </div>
          </div>
        ) : null}
        <div className="w-full block">
          <div className="w-full text-center pb-1">
            <span className="text-xs lg:text-sm text-ag-secondary">
              Quantity
            </span>
          </div>
          <div className="w-full">
            <input
              className="w-full text-center text-ag-secondary-letter text-xs border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
              name="quantity"
              type="text"
              onChange={handleChange}
              value={sectionData.quantity}
              maxLength={10}
              onFocus={onCloseSelect}
            />
          </div>
        </div>
        <div className="w-full pt-3 pt:md-0">
          {/* <div className="w-full py-1 bg-[red] rounded-[20px] flex items-center justify-center"> */}
          <button
            type="button"
            className="bg-green-600 text-white text-xs lg:text-base w-full py:md-1 py-2 rounded-[20px] flex items-center justify-center"
            onClick={handleSubmit}
          >
            {/* <span className="text-green-600 text-[40px]"><HiPlusCircle /></span> */}
            <span className="font-black text-[20px] mr-3">+</span> Add to section
          </button>
          {/* </div> */}
        </div>
      </div>
      {sectionsData.length > 0 ? (
        <div className="pt-10 overflow-x-auto">
          <table className="w-full text-center text-ag-secondary-letter">
            <thead>
              <tr className="bg-ag-secondary-table">
                <th className="w-[33%] border-[0.5px] border-ag-secondary-light text-sm font-normal px-20 md:px-2 py-4">
                  Section
                </th>
                <th className="w-[33%] border-[0.5px] border-ag-secondary-light text-sm font-normal px-10 md:px-2 py-4">
                  Quantity
                </th>
                <th className="w-[33%] border-[0.5px] border-ag-secondary-light text-sm font-normal px-2 py-4">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {sectionsData.map((element, i) => {
                return (
                  <RowsSection
                    key={i}
                    section={element}
                    sectionsOptions={sections}
                    onEdit={(sectionModel) => onEdit(i, sectionModel)}
                    onDelete={onDelete}
                    onCanEdit={onCanEdit}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default TableInventoryAccessory;
