import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { useProductFormUtils } from "./useProductFormUtils";

export const ProductForm = () => {

  const {
    nagsRef,
    handleChangeSel,
    nagSelected,
    setShowNgOptions,
    handleDownSelect,
    showNgOptions,
    selOptions,
    handleSelect,
    handleChange,
    inventory,
    filteredWarehouseOptions: warehouses,
    namesRef,
    showNameOptions,
    setShowNameOptions,
    productSelected,
    removeSelectedWarehouse,
  } = useProductFormUtils();

  return (
    <>
      <div className="w-full flex justify-center self-start">
        <span
          className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
        >
          Nags
        </span>
        <div className="w-[60%] relative" ref={nagsRef}>
          <div className="w-full flex justify-center items-center bg-white text-ag-secondary-letter border border-gray-500 rounded-r-xl truncate">
            <input
              className="w-full p-2 xl:p-3 text-left text-ag-secondary-letter disabled:bg-zinc-200 truncate over"
              name="nags"
              autoComplete="off"
              onChange={(evt) => handleChangeSel(evt, "nags") }
              value={nagSelected}
              onFocus={() => setShowNgOptions(true)}
            />
            <span
              className="text-ag-secondary-letter cursor-pointer px-2"
              onClick={() => handleDownSelect("nags")}
            >
              { showNgOptions ? <BsCaretUpFill /> : <BsCaretDownFill /> }
            </span>
          </div>
          {
            showNgOptions ?
              <div className="w-full bg-white absolute text-center border-x border-b border-zinc-500 rounded-b-xl z-10">
                <ul className="h-[200px] overflow-auto">
                  {
                    selOptions
                      .filter((elemProd) => elemProd.nags.toLowerCase().includes(nagSelected.toLowerCase()))
                      .map((elemProd, i) => (
                        <li
                          key={i}
                          className="relative px-3 py-1 text-ag-secondary-letter text-[14px] cursor-pointer hover:bg-zinc-600 hover:text-white hover:rounded-xl z-50"
                          onClick={() => handleSelect(elemProd._id, "nags")}
                        >
                          {elemProd.nags}
                        </li>
                      ))
                  }
                </ul>
              </div>
              : null
          }
        </div>
      </div>

      <div className="w-full flex flex-col justify-start items-start">
        <div className="w-full flex justify-center items-center">
          <span
            className={`w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 ${inventory?.warehouses?.length > 0 ? "rounded-tl-xl" : "rounded-l-xl"}`}
          >
            Warehouse
          </span>
          <div className="relative w-[60%]">
            <select
              className={`text-ag-secondary-letter border border-gray-500 px-2 xl:px-3 py-2 xl:py-[13px] truncate w-full ${inventory?.warehouses?.length > 0 ? "rounded-tr-xl" : "rounded-r-xl"}`}
              id="select-inventoryAdd-warehouse"
              name="warehouse"
              onChange={handleChange}
            >
              <option value="default"></option>
              {warehouses.map((element, index) => (
                <option key={index} value={element._id}>{element.name}</option>
              ))}
            </select>
          </div>
        </div>
        {inventory?.warehouses?.length > 0 && (
          <>
            {inventory.warehouses.map((warehouse) => (
              <div key={warehouse._id}
                className="text-ag-secondary-letter border border-gray-500 px-2 xl:px-3 py-2 xl:py-[13px]
                  truncate w-full flex items-center justify-center gap-4"
              >
                <input type="checkbox" className="accent-green-600" checked={warehouse.selected} onChange={() => removeSelectedWarehouse(warehouse._id)} title="Remove selection"/>
                <span>{warehouse.name}</span>
              </div>
            ))}
          </>
        )}
      </div>

      <div className="w-full flex justify-center items-center">
        <span
          className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
        >
          Nags Price
        </span>
        <input
          className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl disabled:bg-zinc-200 truncate"
          type="text"
          name="nagPrice"
          value={inventory.nagPrice}
          disabled={true}
        />
      </div>
      <div className="w-full flex justify-center items-center">
        <span
          className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
        >
          Sales Price
        </span>
        <input
          className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
          type="text"
          name="cost"
          value={inventory.cost}
          onChange={handleChange}
          maxLength={12}
        />
      </div>
      <div className="w-full flex justify-center items-center">
        <span
          className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
        >
          Name
        </span>
        <div className="w-[60%] relative" ref={namesRef}>
          <div className={`w-full flex justify-center items-center bg-white text-ag-secondary-letter border border-gray-500 rounded-r-xl truncate`}>
            <input
              className="w-full p-2 xl:p-3 text-left text-ag-secondary-letter truncate"
              name="product"
              autoComplete="off"
              onChange={(evt) => handleChangeSel(evt, "product")}
              value={productSelected}
              title={productSelected}
              onFocus={() => setShowNameOptions(true)}
            />
            <span
              className="text-ag-secondary-letter cursor-pointer px-2"
              onClick={() => handleDownSelect("product")}
            >
              { showNameOptions ? <BsCaretUpFill /> : <BsCaretDownFill /> }
            </span>
          </div>
          {showNameOptions &&
            <div className="w-full bg-white absolute text-center border-x border-b border-zinc-500 rounded-b-xl z-10">
              <ul className="h-[200px] overflow-auto">
                {
                  selOptions.map((elemProd, i) => (
                    <li
                      key={i}
                      className="relative px-3 py-1 text-ag-secondary-letter text-[14px] cursor-pointer hover:bg-zinc-600 hover:text-white hover:rounded-xl z-50 text-ellipsis overflow-hidden"
                      onClick={() => handleSelect(elemProd._id, "product")}
                    >
                      {elemProd.name}
                    </li>
                  ))
                }
              </ul>
            </div>
          }
        </div>
      </div>
      <div className="w-full flex justify-center items-center">
        <span
          className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
        >
          Stock
        </span>
        <input
          className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl disabled:bg-zinc-200 truncate"
          type="text"
          name="stock"
          value={inventory.stock}
          disabled={true}
          maxLength={12}
        />
      </div>
      <div className="w-full flex justify-center items-center">
        <span
          className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
        >
          Minimum Stock
        </span>
        <input
          className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
          type="text"
          name="minStock"
          value={inventory.minStock}
          onChange={handleChange}
          maxLength={12}
        />
      </div>
      <div className="w-full flex justify-center items-center">
        <span
          className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
        >
          Maximum Stock
        </span>
        <input
          className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
          type="text"
          name="maxStock"
          value={inventory.maxStock}
          onChange={handleChange}
          maxLength={12}
        />
      </div>
    </>
  );
};

export default ProductForm;