import { createContext, useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../services/Permits";
import { getWarehouses } from "../../../services/Warehouse";
import { useNavigate, useParams } from "react-router-dom";
import { getInventoryAddId, saveInventoryAdd, updateInventoryAddId } from "../../../services/Inventory";
import { createInventoryAccessory, getInventoryAccessoryById, updateInvetoryAccesroyId } from "../../../services/InventoryAccessory";
import { getProducts } from "../../../services/Product";
import { getAllAccessories } from "../../../services/Accessories";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";

const initialInventory = {
  status: "active",
  warehouse: "",
  warehouses: [],
  product: "",
  nags: "",
  nagPrice: "",
  cost: "",
  maxStock: "",
  minStock: "",
  stock: "",
  racks: [],
  sections: [],
  name: "",
  accessoryPrice: "",
  accessory: "",
};

const initialContext = {
  handleChangeAction: () => { },
  tabButton: null,
  handleChangeTabButton: () => { },
  linkTo: '/inventory',
  loader: false,
  setLoader: () => {},
  inventoryData: [],
  warehouseOptions: [],
  filteredWarehouseOptions:[],
  setFilteredWarehouseOptions: () => { },
  productsOptions: [],
  handleSaveProduct: () => { },
  handleEditProduct: () => { },
  handleSaveAccessory: () => { },
  handleEditAccessory: () => { },
  newInventory: initialInventory,
  setNewInventory: () => { },
  nagSelected: "",
  setNagSelected: () => { },
  productFound: null,
  setProductFound: () => { },
  compatibleVehicles: [],
  setCompatibleVehicles: () => { },
  rackOptions: [],
  setRackOptions: () => { },
  sumStock: 0,
  setSumStock: () => { },
  productSelected: "",
  setProductSelected: () => { },
  action: "add",
  isIdAlreadyAdded: () => { },
  accessoriesOptions: [],
  accessorySelected: "",
  setAccessorySelected: () => { },
  sectionOptions: [],
  setSectionOptions: () => { },
  sortWarehouses: () => { },
  itemsToEdit: [],
  setItemsToEdit: () => { },
  itemsSaved: [],
  setItemsSaved: () => { },
  removeSelectedWarehouse: () => { },
};

export const InventoryContext = createContext(initialContext);

const TAB_OPTIONS = {
  product: "glass",
  accessory: "accessory"
};

export const InventoryContextProvider = ({ children }) => {
  const [tabButton, setTabButton] = useState(null);
  const [linkTo, setLinkTo] = useState('/inventory');
  const [inventoryData, setInventoryData] = useState(null);
  const [loader, setLoader] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const { dataSession } = useContext(PermitsContext);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [filteredWarehouseOptions, setFilteredWarehouseOptions] = useState([]);
  const [productsOptions, setProductsOptions] = useState([]);
  const [accessoriesOptions, setAccessoriesOptions] = useState([]);
  const [newInventory, setNewInventory] = useState(initialInventory);
  const [nagSelected, setNagSelected] = useState("");
  const [accessorySelected, setAccessorySelected] = useState("");
  const [productFound, setProductFound] = useState(null);
  const [compatibleVehicles, setCompatibleVehicles] = useState([]);
  const [rackOptions, setRackOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [sumStock, setSumStock] = useState(0);
  const [productSelected, setProductSelected] = useState("");
  const [action] = useState(id ? "edit" : "add");
  const [itemsToEdit, setItemsToEdit] = useState([]);
  const [itemsSaved, setItemsSaved] = useState([]);

  const resetData = () => {
    setNewInventory(initialInventory);
    setNagSelected("");
    setAccessorySelected("");
    setCompatibleVehicles([]);
    setRackOptions([]);
    setSectionOptions([]);
    setItemsToEdit([]);
    setInventoryData(null);
    setProductFound(null);
    setProductSelected("");
    setItemsSaved([]);
    setFilteredWarehouseOptions(warehouseOptions);
  };

  const handleChangeTabButton = (selected = "glass") => {
    if (selected !== tabButton) {
      if (selected === "glass")setLinkTo("/inventory");
      else if (selected === "accessory") setLinkTo("/inventory/tableInventoryAccessories");
      setTabButton(selected);
      resetData();
    }
  };

  const fetchInventoryData = async () => {
    if (id && tabButton) {
      setLoader(true);
      const res = tabButton === "glass" ? await getInventoryAddId(id) : await getInventoryAccessoryById(id);
      if (res.status === 200) {
        setInventoryData(res.data);
      }
      setLoader(false);
    }
  };

  const fetchData = () => {
    const productsPromise = getProducts().then(response => response);
    const accessoriesPromise = getAllAccessories().then(response => response);
    setLoader(true);
    Promise.allSettled([productsPromise, accessoriesPromise]).then(results => {
      const [productsRes, accessoriesRes] = results;

      if (productsRes.value.status === 200 && productsRes.value.data.length > 0) {
        setProductsOptions(productsRes.value.data);
      }
      if (accessoriesRes.value.status === 200 && accessoriesRes.value.data.length > 0) {
        setAccessoriesOptions(accessoriesRes.value.data);
      }
    }).catch(error => {
      console.log(error);
      toast.error('There was an error while fetching products or accessories data', alertOptions);
    }).finally(() => setLoader(false));
  };

  const fetchWarehouses = async () => {
    try {
      if (!dataSession?.userType || !Array.isArray(dataSession.allWarehouse)) {
        setWarehouseOptions([]);
        return;
      }
      const res = await getWarehouses();
      if (res.status === 200) {
        const warehouses = res.data.filter((element) =>
          !element.deleted &&
            (dataSession.userType === "ADMIN" || dataSession.allWarehouse.some((elem) => element._id === elem._id))
        );
        setWarehouseOptions(warehouses);
        setFilteredWarehouseOptions(warehouses);
      } else {
        setWarehouseOptions([]);
      }
    } catch (error) {
      setWarehouseOptions([]);
    }
  };

  const handleSaveProduct = async (inventoryModel) => {
    try {
      setLoader(true);
      const res = await saveInventoryAdd(inventoryModel);
      setLoader(false);
      if (res.status === 200) {
        toast.success("Product added successfully", alertOptions);
        navigate("/inventory");
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
    } catch (error) {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    }
  };

  const handleEditProduct = async (inventoryModel) => {
    if (tabButton === TAB_OPTIONS.product) {
      try {
        setLoader(true);
        const res = await updateInventoryAddId(inventoryModel._id, inventoryModel);
        setLoader(false);
        if (res.status === 200) {
          toast.success("Product updated successfully", alertOptions);
          navigate("/inventory");
        } else {
          toast.warning(res.response.data.message, alertOptions);
        }
      } catch (error) {
        setLoader(false);
        toast.warning(error.response.data.message, alertOptions);
      }
    }

  };

  const handleSaveAccessory = async (inventoryModel) => {
    try {
      setLoader(true);
      let newinventoryModel = {
        ...inventoryModel,
        by: {
          _id: dataSession._id
        }
      };
      const res = await createInventoryAccessory(newinventoryModel);
      setLoader(false);
      if (res.status === 200) {
        toast.success("Accessory added successfully", alertOptions);
        navigate("/inventory/tableInventoryAccessories");
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
    } catch (error) {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    }
  };

  const handleEditAccessory = async (inventoryModel) => {
    try {
      setLoader(true);
      let newinventoryModel = {
        ...inventoryModel,
        lastUpdateBy: {
          _id: dataSession._id
        }
      };
      const res = await updateInvetoryAccesroyId(
        inventoryModel._id,
        newinventoryModel
      );
      setLoader(false);
      if (res.status === 200) {
        toast.success("Accessory updated successfully", alertOptions);
        navigate("/inventory/tableInventoryAccessories");
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
    } catch (error) {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    }
  };

  const isIdAlreadyAdded = (array = [], element = {}) => {
    const itemFound = array.find(rckElmnt => rckElmnt._id === element._id);
    return !!itemFound;
  };

  const sortWarehouses = (warehouseA, warehouseB) => {
    if (warehouseA.name < warehouseB.name) {
      return -1;
    }
    else if (warehouseA.name > warehouseB.name) {
      return 1;
    }
    return 0;
  };

  const removeSelectedWarehouse = (wareHouseId) => {
    const filteredWarehouses = newInventory.warehouses.filter(warehouse => {
      if (warehouse._id === wareHouseId) setFilteredWarehouseOptions([...filteredWarehouseOptions, {...warehouse, selected: false}]);
      else return warehouse;
    });
    if (tabButton === "glass") {
      const filteredRacks = rackOptions.filter(rack => rack.warehouseId !== wareHouseId);
      const filteredAddedRacks = newInventory.racks.filter(rack => rack.warehouseId !== wareHouseId);
      setRackOptions(filteredRacks);
      setNewInventory({ ...newInventory, warehouses: filteredWarehouses, racks: filteredAddedRacks });
    }
    else {
      const filteredSections = sectionOptions.filter(section => section.warehouseId !== wareHouseId);
      const filteredAddedSections = newInventory.sections.filter(section => section.warehouseId === wareHouseId);
      setSectionOptions(filteredSections);
      setNewInventory({ ...newInventory, warehouses: filteredWarehouses, sections: filteredAddedSections });
    }
  };

  useEffect(() => {
    if (action === "edit" && tabButton) fetchInventoryData();
    if(dataSession && warehouseOptions.length === 0) fetchWarehouses();
    if(productsOptions.length === 0 || accessoriesOptions.length === 0) fetchData();
  }, [tabButton, dataSession, action]);

  return (
    <InventoryContext.Provider
      value={{
        tabButton,
        handleChangeTabButton,
        linkTo,
        loader,
        setLoader,
        inventoryData,
        warehouseOptions,
        filteredWarehouseOptions,
        setFilteredWarehouseOptions,
        productsOptions,
        handleSaveProduct,
        handleEditProduct,
        handleSaveAccessory,
        handleEditAccessory,
        dataSession,
        newInventory,
        setNewInventory,
        nagSelected,
        setNagSelected,
        productFound,
        setProductFound,
        compatibleVehicles,
        setCompatibleVehicles,
        rackOptions,
        setRackOptions,
        sumStock,
        setSumStock,
        productSelected,
        setProductSelected,
        action,
        isIdAlreadyAdded,
        accessoriesOptions,
        accessorySelected,
        setAccessorySelected,
        sectionOptions,
        setSectionOptions,
        sortWarehouses,
        itemsToEdit,
        setItemsToEdit,
        itemsSaved,
        setItemsSaved,
        removeSelectedWarehouse
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};

export const useInventoryContext = () => {
  return useContext(InventoryContext);
};