import { useInventoryContext } from "../InventoryContext";
import { useFormInventoryUtils } from "./useFormInventoryUtils";
import CompatibleVehicles from "./compatible-vehicles/CompatibleVehicles";
import ProductForm from "./products/ProductForm";
import AccessoryForm from "./accessories/AccessoryForm";
import WarehousesItemsForm from "./WarehousesItemsForm";

const FormInventory = () => {
  const {
    tabButton,
    handleChangeTabButton,
  } = useInventoryContext();
  const {
    handleSubmit,
  } = useFormInventoryUtils();

  const defaultTab = tabButton === 'glass';

  return (
    <form className="grid grid-cols-12 gap-6" onSubmit={handleSubmit}>
      <section className="col-span-12 md:col-span-8">
        <div className="grid gap-3 lg:grid-cols-2 items-center">
          <h2 className="text-ag-secondary-light text-2xl font-bold">Select information</h2>
          <div className="flex gap-2 text-white justify-center">
            <button
              className={`${defaultTab ? "bg-ag-primary-light" : "bg-ag-secondary-dark" } min-w-[40%] rounded-md px-2`}
              type="button"
              onClick={() => handleChangeTabButton('glass')}
            >
              Glass
            </button>
            <button
              className={`${!defaultTab ? "bg-ag-primary-light" : "bg-ag-secondary-dark" } min-w-[40%] rounded-md px-2`}
              type="button"
              onClick={() => handleChangeTabButton('accessory')}
            >
              Accessory
            </button>
          </div>
          {defaultTab ? <ProductForm/> : <AccessoryForm/>}
        </div>
      </section>
      {defaultTab &&
        <section className="col-span-12 md:col-span-4 border-t md:border-l md:border-t-0 border-ag-secondary md:px-6 pt-8 md:pt-0">
          <CompatibleVehicles />
        </section>
      }
      <section className="col-span-12 border-t border-ag-secondary">
        <WarehousesItemsForm />
      </section>
      <div className="flex items-end col-span-12 justify-end">
        <button type="submit" className=" min-w-[10rem] bg-green-600 text-white lg:text-base py-1 rounded-l flex items-center justify-center">Save</button>
      </div>
    </form>
  );
};

export default FormInventory;