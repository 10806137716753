const ErrorModal = ({title = 'Unexpected error', content = 'An error has occured. Try again', onClose = () =>{}}) => {
  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="w-[320px] md:w-[500px]">
            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                  <h1 className="text-xl text-ag-secondary-letter font-bold">
                    {title}
                  </h1>
                </div>
              </div>
              <div className="relative px-5 py-3 flex-auto">
                <div className="space-y-5">
                  <div className="text-center font-normal py-4">
                    <h3 className="text-ag-secondary-letter">{content}</h3>
                  </div>
                  <div className="flex justify-center items-center space-x-3">
                    <button
                      type="button"
                      className="px-5 py-2 bg-ag-secondary text-white text-sm rounded-md min-w-[50%]"
                      onClick={() => onClose(false)}
                    >
                      Back
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
    </>
  );
};

export default ErrorModal;