import { useEffect } from "react";
import { parseTextTypeGlass } from "../../../../../utils/Utilities";
import { useInventoryContext } from "../../InventoryContext";
import { getCompatVehiclesWithInterchangesByNag } from "../../../../../services/Product";

const CompatibleVehicles = () => {
  const {
    compatibleVehicles,
    setCompatibleVehicles,
    nagSelected,
    setProductFound,
    productFound,
    setLoader: onLoader,
    productsOptions: products,
  } = useInventoryContext();

  useEffect(() => {
    if (nagSelected) {
      const productFound = products.find(prodSel => prodSel.nags === nagSelected);
      let nag = productFound?.nags ? productFound?.nags : productFound?.compatible_vehicles[0].nag;
      if (productFound) {
        onLoader(true);
        setProductFound(productFound);
        getCompatVehiclesWithInterchangesByNag(nag).then(res => {
          if (res.status === 200 && res.data.length > 0) setCompatibleVehicles(res.data);
          else setCompatibleVehicles([]);
        }).finally(() => onLoader(false));
      }
      else {
        setCompatibleVehicles([]);
        setProductFound(null);
      }
    }
  }, [nagSelected]);

  return (
    <>
      <h1 className="text-ag-secondary-light text-xl font-bold">
        Compatible Vehicles {productFound && `of: ${productFound?.nags ? productFound?.nags : productFound?.compatible_vehicles[0].nag}`}
      </h1>
      <div className="px-8 py-5 sm:max-h-[500px] lg:max-h-[300px] overflow-y-auto">
        {compatibleVehicles?.length > 0 && (
          <ul className="flex flex-col gap-2 list-disc ">
            {compatibleVehicles?.map(prodComp => (
              <li key={prodComp._id} className="w-full text-ag-secondary">
                {String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length - 1)]}` : prodComp?.year}{' '}
                {prodComp?.brand}{' '}
                {prodComp?.model}{' '}
                {prodComp?.type_car}{' '}
                {parseTextTypeGlass(prodComp?.type_glass)}
              </li>
            )) }
          </ul>
        )}
      </div>
    </>
  );
};

export default CompatibleVehicles;