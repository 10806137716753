import { useEffect } from "react";
import { toast } from "react-toastify";
import { useInventoryContext } from "../../../InventoryContext";
import { alertOptions } from "../../../../../../utils/Utilities";

export const useSectionsFormUtils = ({sectionData = [], setSectionData = () => {}}) => {
  const {
    newInventory: inventory,
    sumStock,
    sectionOptions,
    setSumStock,
    setNewInventory: setInventory,
    setItemsToEdit: setSectionsToEdit,
    itemsToEdit: sectionsToEdit,
    action,
    isIdAlreadyAdded,
    itemsSaved: sectionsData,
    setItemsSaved: setSectionsData
  } = useInventoryContext();

  const addSection = (sectionElement) => {
    if (sectionElement._id === "" || (sectionElement.quantity === 0 || sectionElement.quantity === "")) {
      toast.warning("Please select a section and quantity to add", alertOptions);
    } else {
      if (!isIdAlreadyAdded(inventory.sections, sectionElement)) {
        setInventory({...inventory, sections: [...inventory.sections, sectionElement]});
        setSumStock(Number(sumStock) + Number(sectionElement.quantity));
      } else {
        toast.warning("The selected section is already added", alertOptions);
      }
    }
  };

  const editSection = (sectionIndex, sectionElement) => {
    let sumStockAux = 0;
    const sectionsAux = inventory.sections.map((rckElmnt, i) => {
      if (i === sectionIndex) {
        sumStockAux += Number(sectionElement.quantity);
        return { ...sectionElement, _id: sectionElement._id, quantity: sectionElement.quantity};
      }
      sumStockAux += Number(rckElmnt.quantity);
      return rckElmnt;
    });
    setSumStock(sumStockAux);
    setInventory({...inventory, sections: sectionsAux});
  };

  const deleteSection = (idSection) => {
    let sumStockAux = 0;
    const sectionsAux = inventory.sections.filter(sectionElement => {
      if (sectionElement._id !== idSection) {
        sumStockAux += Number(sectionElement.quantity);
        return sectionElement;
      }
    });
    setSumStock(sumStockAux);
    setInventory({...inventory, sections: sectionsAux});
  };

  const handleCanEdit = (sectionElement, sts) => {
    if (sts) {
      setSectionsToEdit([...sectionsToEdit, sectionElement._id]);
    } else {
      const newSections = sectionsToEdit.filter(elem => elem !== sectionElement._id);
      setSectionsToEdit(newSections);
    }
  };

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "quantity") {
      const pattern = new RegExp(/^[0-9\s]+$/g);
      if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
        setSectionData({...sectionData, [evt.currentTarget.name]: evt.currentTarget.value});
      }
    } else {
      const foundSection = sectionOptions.find(eleRck => (eleRck._id === evt.target.value));
      if (foundSection) {
        setSectionData({...sectionData,
          [evt.currentTarget.name]: evt.currentTarget.value,
          name: foundSection ? foundSection.name : ""
        });
      }
      else setSectionData({warehouseId: "", _id: "", quantity: "", name: ""});

    }
  };

  const handleSubmit = (warehouseId) => {
    if (inventory?.maxStock === "" || inventory?.maxStock === 0) {
      console.log('exec this');
      toast.warning("Please fill in the maximum stock field", alertOptions);
    } else if(action === "add") {
      addSection({...sectionData, warehouseId});
      setSectionData({...sectionData, warehouseId:"", _id: "", quantity: "", name: ""});
    }
    else if (action === "edit") {
      const sectionData = warehouseId;
      if (sectionData._id !== "default") {
        addSection(sectionData);
      }
      else {
        toast.warning("Please select a section and quantity to add", alertOptions);
      }
    }
  };

  useEffect(() => {
    setSectionsData(inventory.sections);
  }, [inventory?.sections]);

  return {
    sectionsData,
    editSection,
    deleteSection,
    handleCanEdit,
    handleChange,
    sectionOptions,
    handleSubmit,
    inventory,
  };
};
