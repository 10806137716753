import { useEffect } from "react";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../../../utils/Utilities";
import { useInventoryContext } from "../../../InventoryContext";

export const useRacksFormUtils = ({rackData = [], setRackData = () => {}}) => {
  const {
    newInventory: inventory,
    sumStock,
    rackOptions,
    setSumStock,
    setNewInventory: setInventory,
    setItemsToEdit: setRacksToEdit,
    itemsToEdit: racksToEdit,
    action,
    isIdAlreadyAdded,
    itemsSaved: racksData,
    setItemsSaved: setRacksData
  } = useInventoryContext();

  const addRack = (rackElement) => {
    if (rackElement._id === "" || (rackElement.quantity === 0 || rackElement.quantity === "")) {
      toast.warning("Please select a rack and quantity to add", alertOptions);
    } else {
      if (!isIdAlreadyAdded(inventory.racks, rackElement)) {
        setInventory({...inventory, racks: [...inventory.racks, rackElement]});
        setSumStock(Number(sumStock) + Number(rackElement.quantity));
      } else {
        toast.warning("The selected rack is already added", alertOptions);
      }
    }
  };

  const editRack = (rackIndex, rackElement) => {
    let sumStockAux = 0;
    const racksAux = inventory.racks.map((rckElmnt, i) => {
      if (i === rackIndex) {
        sumStockAux += Number(rackElement.quantity);
        return { ...rackElement, _id: rackElement._id, quantity: rackElement.quantity};
      }
      sumStockAux += Number(rckElmnt.quantity);
      return rckElmnt;
    });
    setSumStock(sumStockAux);
    setInventory({...inventory, racks: racksAux});
  };

  const deleteRack = (idRack) => {
    let sumStockAux = 0;
    const racksAux = inventory.racks.filter(rackElement => {
      if (rackElement._id !== idRack) {
        sumStockAux += Number(rackElement.quantity);
        return rackElement;
      }
    });
    setSumStock(sumStockAux);
    setInventory({...inventory, racks: racksAux});
  };

  const handleCanEdit = (rckElem, sts) => {
    if (sts) {
      setRacksToEdit([...racksToEdit, rckElem._id]);
    } else {
      const newRacks = racksToEdit.filter(elem => elem !== rckElem._id);
      setRacksToEdit(newRacks);
    }
  };

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "quantity") {
      const pattern = new RegExp(/^[0-9\s]+$/g);
      if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
        setRackData({...rackData, [evt.currentTarget.name]: evt.currentTarget.value});
      }
    } else {
      const foundRack = rackOptions.find(eleRck => (eleRck._id === evt.target.value));
      if (foundRack) {
        setRackData({...rackData,
          [evt.currentTarget.name]: evt.currentTarget.value,
          name: foundRack ? foundRack.name : ""
        });
      }
      else setRackData({warehouseId: "", _id: "", quantity: "", name: ""});

    }
  };

  const handleSubmit = (warehouseId) => {
    if (inventory?.maxStock === "" || inventory?.maxStock === 0) {
      toast.warning("Please fill in the maximum stock field", alertOptions);
    } else if(action === "add") {
      addRack({...rackData, warehouseId});
      setRackData({...rackData, warehouseId:"", _id: "", quantity: "", name: ""});
    }
    else if (action === "edit") {
      const rackData = warehouseId;
      if (rackData._id !== "default") {
        addRack(rackData);
      }
      else {
        toast.warning("Please select a rack and quantity to add", alertOptions);
      }
    }
  };

  useEffect(() => {
    setRacksData(inventory.racks);
  }, [inventory?.racks]);

  return {
    racksData,
    editRack,
    deleteRack,
    handleCanEdit,
    handleChange,
    rackOptions,
    handleSubmit,
    inventory,
  };
};
